<template>
    <div>
        <app-table
            url="invoices"
            :columns="columns"
            :actions="actions"
            :params="tableParams"
        ></app-table>
        <v-dialog
            v-model="showMakePaymentModal"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title>Make Payment</v-card-title>
                <v-card-text>
                    <v-text-field
                        type="text"
                        label="Amount"
                        v-model="makePaymentForm.amount"
                        prefix="$"
                    />
                    <div>
                        <div>Pay with your credit card via Stripe.</div>
                        <v-radio-group
                            v-model="makePaymentForm.card"
                            class="mt-0"
                        >
                            <template
                                v-for="item in paymentMethods"
                            >
                                <v-radio
                                    :key="item.id"
                                    :label="`**** ${item.last4_for_human} (expires ${item.expire})`"
                                    :value="item.id"
                                    class="my-2"
                                ></v-radio>
                                <v-divider :key="item.id" />
                            </template>
                            <v-radio
                                label="Use a new payment method."
                                value="new"
                                class="my-2"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                    <div v-if="makePaymentForm.card === 'new'">
                        <v-text-field
                            type="text"
                            label="Card Name"
                            autofocus
                            v-model="newCardForm.cardHolderName"
                            :rules="[() => !!newCardForm.cardHolderName || 'This field is required']"
                        />
                        <v-stripe-card
                            v-model="newCardForm.source"
                            :api-key="stripeKey"
                            label="Card Number"
                            :custom-style="{base: {
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}"
                            font-name="Montserrat"
                            ref="stripeCard"
                        />
                        <v-checkbox
                            v-model="makePaymentForm.save"
                            label="Save payment information to my account for future payments."
                            class="mt-0"
                        ></v-checkbox>
                        <v-checkbox
                            v-if="user.default_cc && makePaymentForm.save"
                            v-model="makePaymentForm.setAsDefault"
                            label="Set as default"
                            class="mt-0"
                        ></v-checkbox>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="grey"
                        @click="closeMakePaymentModal"
                        :disabled="makingPayment"
                    >Cancel</v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="pay"
                        :loading="makingPayment"
                    >Pay {{ makePaymentForm.amountForHuman }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import Http from '@/utils/httpClient';
    import Table from '@/components/Table.vue';
    import VStripeCard from 'v-stripe-elements/lib/VStripeCard';
    import Form from '@/utils/form';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            appTable: Table,
            VStripeCard,
        },
        computed: {
            ...mapGetters(['user'])
        },
        data() {
            return {
                paymentMethods: [],
                stripeKey: process.env.VUE_APP_STRIPE_KEY,
                showMakePaymentModal: false,
                makingPayment: false,
                makePaymentForm: new Form({
                    invoiceId: null,
                    amount: 0,
                    card: null,
                    amountForHuman: null,
                    save: false,
                    setAsDefault: false,
                    paymentMethod: null,
                }),
                newCardForm: new Form({
                    cardHolderName: null,
                    source: null,
                }),
                columns: [
                    {
                        text: 'ID',
                        value: 'label',
                    },
                    {
                        text: 'Due Date',
                        value: 'due_date_for_human',
                    },
                    {
                        text: 'Status',
                        value: 'status',
                    },
                    {
                        text: 'Amount',
                        value: 'amount_with_tax_for_human',
                        align: 'end',
                    },
                    {
                        text: 'Paid',
                        value: 'amount_paid_for_human',
                        align: 'end',
                    },
                    {
                        text: 'Balance',
                        value: 'balance_with_tax_for_human',
                        align: 'end',
                    },
                ],
                actions: [
                    // {
                    //     label: 'HTML',
                    //     icon: 'fab fa-html5 fa-fw',
                    //     dropdown: (invoice) => invoice.html_url_for_customer
                    // },
                    {
                        label: 'PDF',
                        icon: 'fal fa-file-pdf fa-fw',
                        href: (invoice) => invoice.pdf_url_for_customer
                    },
                ],
                tableParams: {
                    query: {
                        invoice: [
                            'id',
                            'customer_id',
                            'label',
                            'due_date_for_human',
                            'invoice_date_for_human',
                            'status',
                            'amount_with_tax_for_human',
                            'amount_paid_for_human',
                            'balance_with_tax_for_human',
                            //'html_url_for_customer',
                            'pdf_url_for_customer',
                        ],
                    }
                }
            }
        },
        watch: {
            'makePaymentForm.amount': (amount) => {
                this.makePaymentForm.set({
                    amountForHuman: new Intl.NumberFormat('en-AU', {
                        style: 'currency',
                        currency: 'AUD',
                    }).format(amount)
                });
            },
        },
        methods: {
            closeMakePaymentModal() {
                this.showMakePaymentModal = false;
            },
            pay() {
                this.$root.$loader.open({ text: 'Processing...' });
                if (this.makePaymentForm.card === 'new' && this.makePaymentForm.save) {
                    this.createStripeSetupIntent();
                    return;
                }

                if (this.makePaymentForm.card === 'new' && !this.makePaymentForm.save) {
                    this.createStripePaymentMethod();
                    return;
                }

                this.processPayment();
            },
            processPayment() {
                Http.post('customer/billing/stripe/payments', this.makePaymentForm.payload())
                    .then(() => {
                        this.refreshTable = true;
                        this.$root.$loader.close();
                        this.$root.$snackbar.open({
                            text: 'Payment successful! A copy of your receipt has been sent to your email.',
                            type: 'success',
                            timeout: 0,
                        });
                        this.closeMakePaymentModal();
                    })
                    .catch(({ response }) => {
                        this.$root.$loader.close();
                        this.$root.$snackbar.open({
                            text: response.data.message,
                            type: 'error',
                            timeout: 0,
                        })
                    })
            },
            createStripePaymentMethod() {
                this.$refs.stripeCard.stripe.createPaymentMethod({
                    type: 'card',
                    card: this.$refs.stripeCard.card,
                    billing_details: {
                        name: this.newCardForm.cardHolderName
                    }
                })
                .then(({ error, paymentMethod }) => {
                    if (!error) {
                        this.makePaymentForm.set({
                            paymentMethod: paymentMethod.id
                        })

                        this.processPayment();
                    } else {
                        this.$root.$loader.close();
                    }
                });
            },
            createStripeSetupIntent() {
                Http.post('customer/billing/stripe/setup_intents', this.newCardForm.payload())
                    .then(async ({ data }) => {
                        const { setupIntent, error } = await this.$refs.stripeCard.stripe.confirmCardSetup(
                                data.client_secret, {
                                    payment_method: {
                                        card: this.$refs.stripeCard.card,
                                        billing_details: { name: this.newCardForm.cardHolderName }
                                    }
                                }
                            );

                        if (!error) {
                            this.makePaymentForm.set({
                                paymentMethod: setupIntent.payment_method
                            })

                            this.processPayment();
                        }
                    })
                    .catch(({ response }) => {
                        this.$root.$loader.close();
                        this.newCardForm.errors.record(response.data);
                    })
            }
        }
    }
</script>
